$color1:#107AA5;
$color2:#ec616b;
$gray:#4c4c4c;
$main:#004689;
.effect{transition:all 500ms ease-in-out 0s;}
@font-face {
    font-family: oxy;
    src: url(../fonts/Oxy_Vietnam.ttf) format('truetype'), url(../fonts/Oxy_Vietnam.woff) format('woff')
}
@font-face{ font-family:fbb; src:url(../fonts/UTM_Bebas.ttf) format('truetype'),url(../fonts/UTM_Bebas.woff) format('woff')}
@font-face {
    font-family: oxy_bold;
    src: url(../fonts/Oxy_Vietnam_Bold.ttf) format('truetype'), url(../fonts/Oxy_Vietnam_Bold.woff) format('woff')
}
@font-face{ font-family:Roboto; src:url(../fonts/RobotoCondensed-Regular.woff) format('woff')}
%fox{
    font-family: oxy;
}
%foxb{
    font-family: oxy_bold;
}
%hd-shadow{
	box-shadow:0 4px 8px #ddd;
}
$r1440: "(max-width: 1440px)";
$r1366: "(max-width: 1366px)";
$r1280: "(max-width: 1280px)";
$r1024: "(max-width: 1024px)";
$r768: "(max-width: 768px)";
$r480: "(max-width: 480px)";
$r414: "(max-width:414px)";
$r375: "(max-width:375px)";
$r360: "(max-width:360px)";
$r320: "(max-width:320px)";
.aligncenter {
    margin: 0 auto;
    display: block;
    max-width: 100%;
}
.pc{
    @media #{$r480} {
        display: none;
    }
}
.mb{
    display: none;
    @media #{$r480} {
        display: block;
        text-transform: uppercase;
        color: #0153a2;
        text-align: center;
        font-weight: bold;
        font-family: sans-serif;
    }
}


.pulse-wr {
    position: fixed;
    bottom: 50px;
    right: 15px;
    width: 120px;
    height: 120px;
    margin: 0 auto;
    z-index: 8;
    -webkit-perspective: 1000;
    -webkit-backface-visibility: hidden;
    background: transparent;
	&:hover,&:focus{
		text-decoration: none;
	}
}
.popup-pulse {
    color: #fff;
    font-size: 22px;
    font-weight: bold;
    position: relative;
    top: 50%;
    left: 50%;
    margin-left: -35px;
    margin-top: -35px;
    background-color: #6f9a37;
    background-repeat: no-repeat;
    background-position: center center;
    background-size: 50px 50px;
    display: block;
    width: 70px;
    height: 70px;
    text-transform: uppercase;
    text-align: center;
    line-height: 73px;
    border: none;
    border-radius: 50%;
    cursor: pointer;
    box-shadow: 0 0 0 0 rgba(111, 154, 55, 0.5);
    -webkit-animation: zoom 1.3s infinite;
    animation: zoom 1.3s infinite;
}

.popup-pulse span {
    position: absolute;
    top: 22px;
    left: 14px;
    font-size: 13px;
    line-height: 14px;
}

@-webkit-keyframes zoom {
    0% {
        transform: scale(0.9);
    }
    70% {
        transform: scale(1);
        box-shadow: 0 0 0 25px rgba(111, 154, 55, 0);
    }
    100% {
        transform: scale(0.9);
        box-shadow: 0 0 0 0px rgba(111, 154, 55, 0);
    }
}

@keyframes zoom {
    0% {
        transform: scale(0.9);
    }
    70% {
        transform: scale(1);
        box-shadow: 0 0 0 25px rgba(111, 154, 55, 0);
    }
    100% {
        transform: scale(0.9);
        box-shadow: 0 0 0 0px rgba(111, 154, 55, 0);
    }
}
body{
	font-size:14px;
	position: relative;
	@media #{$r768} {
		padding:0;
	}
}
.slogan{
	width:100%;
	background:#004689;
	display: none;
	@media #{$r414}{
		display: block;
	}
	&__tt{
		display: block; 
		width:100%; 
		padding:8px 5px;
		font-size:13px;
		background: #053a6d;
		color:#fff;
		text-transform: uppercase;
		cursor: pointer;
		border:none;
		outline: none;
		@media #{$r375} { 
			font-size:12px;
		}
	}
	&__ct{
		display: none;
	}
	ul{
		margin:0;
		padding:2px;
		border-radius: 0;
		display: flex;
		flex-wrap: wrap;
		justify-content: center;
		li{
			list-style: none;
			text-transform: uppercase;
			padding:1px;
			font-size:11px;
			width:30%;
			background:#053a6d;
			color:#fff;
			margin:2px;
			i{
				color:#ff5251;
				margin-right:3px;
				font-size:16px;
			}
		}
	}
}	
.container{
	position: relative;
	width:1170px;
}
.kq{
	background: #004689;
	position: fixed;
	left:0;
	bottom:0;
	width:100%;
	color:#fff;
	z-index: 999;
	padding:5px 0;
	display: none;
	p{
		margin:0;
	}
}
.navbar {
	z-index: 9;
}

.pagetop{
	height: 90px;
	position: relative;
	@media #{$r1024}{
		height:81px;
	}  
	@media #{$r768}{
		height:80px;
	}  
	@media #{$r414} {
		height: 60px;
	}
}
.boxtop{
	position: absolute;
	top:0;
	left:0;
	background: #0153a2;
	color:#fff;
	height: 40px;
	overflow: hidden;
	width:100%;
	.tt{
		float:left;
		display: block;
		width:340px;
		padding:8px 0;
		font-size:13px;
	}
	ul{
		margin:0;
		li{
			list-style: none;
			float:left;
			text-transform: uppercase;
			padding:10px 12px;
			font-weight: bold;
			font-size:12px;
			i{
				color:#ff5251;
			}
		}
	}
}
.socal{
	width:100px;
	text-align: right;
	float:right;
	padding:5px;
	span{
		padding-right:5px;
	}
}

.ftad{
	display:table;
	position:fixed;
	bottom:0;
	width:100%;
	z-index:999;
	border-top:2px solid #fff;
	height:40px;
	& > div{
		display:table-cell;
		width:50%;
		vertical-align:top;
		&:nth-child(1){
			background:#095378;
		}
		&:nth-child(2){
			background:#D2342E;
			i{
				@media #{$r320}{
					display: none;
				}
			}
		}
		a{
			display:block;
			font-family:'Roboto Condensed';
			font-size:20px;
			line-height: 38px;
			text-transform:uppercase;
			color:#fff;
			cursor: pointer;
			&:hover{
				text-decoration: none;
			}
		}
		i{
			float:left;
			width:35px;
			height:28px;
			margin:0 0 0 8px;
			font-size:24px;
			
		}
	}
}
.refuse {
	position: fixed;
	bottom: 0;
	background: #003a56;
	color: #fff;
	text-align: center;
	font-style: italic;
	width: 100%;
	padding: 5px 0;
	z-index: 5;
	@media #{$r414}{
		display: none;
	}
}
.modal-clip{
	display: table;
    margin: 0 auto;
    max-width: 550px;
    position: relative;
	cursor: pointer;
	background:#000;
	&:before{
		content:'';
		display: block;
		position: absolute;
		z-index: 1;
		background: url(../images/clip-icon.png) center no-repeat;
		transition: 0.3s ease-out;
		background-size: 100%;
		width: 50px;
		height: 50px;
		left: calc(50% - 23px);
		top: calc(50% - 23px);
		transition: 0.3s;
		-webkit-filter: grayscale(100%);
		filter: grayscale(100%);
		opacity: 1;
	}
	img{
		position: relative;
		opacity: .5;
		display: block;
	}
}
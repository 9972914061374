#ftmng {
	.row {
		margin: 0 ;
	}
}
a{
	cursor: pointer;
}
.modal-title{
	text-transform: uppercase;
	font-weight: 600;
}
section{
	padding:20px 0;
	@media #{$r768} { 
		padding:30px 0;
	}
}
.container {
	@media #{$r1024} {
		width: 970px;
	}
	@media #{$r768} {
		width: 750px;
	}
	@media #{$r414}{
		width: 414px;
	}
	@media #{$r375}{
		width: 375px;
	}
	@media #{$r360}{
		width: 360px;
	}
	@media #{$r320}{
		width: 320px;
	}
}
.boxtop {
	.container {
		padding: 0;
	}
	.row {
		margin: 0;
	}
	.col-sm-10 {
		max-width: 100%;
		flex: 0 0 100%;
		padding: 0;
	}
	.col-sm-2 {
		display: none;
	}
	.tt {
		width: 329px;
	}
	ul {
		li {
			padding: 10px 7px;
			font-size: 11px;
		}
	}
}

.regist {
	text-align: center;
	padding-top: 70px;
	.btn {
		background: #B00101;
		border-radius: 26px;
		color:#fff;
		padding: 8px 20px;
		font-weight: 600;
		font-size: 20px;
		text-transform: uppercase;
		@media #{$r1024}{
			padding:11px 14px;
		}
		@media #{$r768} {
			font-size: 12px;
			padding-top: 10px;
		}	
	}
}
.pagett {
	color: #50ACDE;
	text-align: center;
	margin-bottom:10px;
	margin-top: 0;
	font-size: 25px;
	font-weight: bold;
	text-transform: uppercase;
	@media #{$r1024} {
		font-size: 22px;
	}
	@media #{$r414}{
		font-size: 20px;
		line-height: 28px;
	}
	@media #{$r375}{
		font-size: 18px;
		line-height: 25px;
	}
	@media #{$r320}{
		font-size: 17px;
	}
}
.lines1 {
	background: url(../images/line-1.png) no-repeat center 0;
	padding: 15px 0 0;
}
.lines2 {
	background: url(../images/line.png) no-repeat center 0;
	padding: 15px 0 10px;
	@media #{$r375}{
		padding: 5px 0;
	}
}
.subtt{
	text-align: center;
	margin:-5px 0 10px;
	font-size: 18px;
	color: #CF0101;
	font-weight: bold;
	font-style: italic;
	@media #{$r768} {
		font-size: 17px;
	}
	@media #{$r375} {
		margin: 8px 0 10px;
	}
	@media #{$r320}{
		font-size: 12px;
	}
}
.icon-clip {
	display: table;
	margin: 0 auto;
	max-width: 550px;
	position: relative;
	cursor: pointer;
	background: #000;

	&:before {
		content: '';
		display: block;
		position: absolute;
		z-index: 1;
		background: url(../images/clip-icon.png) center no-repeat;
		transition: 0.3s ease-out;
		background-size: 100%;
		width: 50px;
		height: 50px;
		left: calc(50% - 23px);
		top: calc(50% - 23px);
		transition: 0.3s;
		-webkit-filter: grayscale(100%);
		filter: grayscale(100%);
		opacity: 1;

		@media #{$r768} {
			width: 30px;
		}
	}

	img {
		position: relative;
		opacity: .6;
		display: block;
		transition: 0.3s;
	}

	&:hover {
		img {
			opacity: .8;
		}
	}
}
aside{
	@media #{$r768} {
		display: none;
	}
	margin-left:-10px;
	@media #{$r768}{
		margin:0 -15px;
	}
	@media #{$r414}{
		margin:0;
		display: none !important;
	}
	article{
		padding:15px;
		margin-bottom: 16px;
		@media #{$r768}{
			margin-bottom:0;
			margin-top:10px;
		}
	}
}
.sidebar{
	&Title{
		color: #636363;
		text-transform: uppercase;
		height: 45px;
		padding: 5px 0 10px 0;
		font-size: 16px;
		border-bottom: 1px solid #eee;
		position: relative;
		@extend %font1;
		font-weight: 600;
		color:$color1;
		&:after{
			content: "";
			border-bottom: 3px solid #77b2ea;
			width: 80px;
			position: absolute;
			left: 0;
			bottom: -2px;
			display: block;
		}
	}
	&List{
		ul{
			margin:10px 0;
			list-style: none;
			padding:0;
		}
		li{
			padding-left:0;
			a{
				transition: 0.6s;
				&:hover{
					transition: 0.6s;
					color:#f26649;
				}
			}
		}
	}
	&Thumb{
		margin: 20px 0 0;
		.media{
			padding-bottom:10px;
			@media #{$r414}{
				flex-wrap: wrap;
			}
			&-pic{
				width:60px;
				img{
					width:100%;
					height:60px;
					border-radius: 4px;
				}
			}
			&-body{
				padding-left:15px;
				align-self: center;
			}
			&-title{
				color:#333;
				font-size:13px;
				height:40px;
				overflow: hidden;
			}
			
			
		}
	}
	&Photo{
		margin:20px 0;
		img{
			width:100%;
		}
	}
	&Slide{
		margin:20px 0;
		figure{
			position: relative;
			
		}
		figcaption{
			position: absolute;
			left:0;
			bottom:0;
			padding:5px 0;
			text-align: center;
			width:100%;
			background:rgba(0,0,0,.1);
			color:#fff;
		}
		.owl-theme .owl-nav [class*=owl-]{
			position: absolute;
			top:40%;
			font-size:28px;
			outline: none;
			line-height: 20px;
			padding:4px 7px;
		}
		.owl-theme .owl-nav .owl-prev{
			left:0px;
			
		}
		.owl-theme .owl-nav .owl-next{
			right:0px;
		}
		.owl-theme .owl-nav [class*=owl-]:hover{
			background:rgba(0,0,0,.3);
			color:#333;
		}
		.owl-theme .owl-nav [class*=owl-]{
			background:rgba(0,0,0,.3);
			color:#fff;
		}
	}
}


.breadcrumb{
	width: 100%;
	height: auto;
	background: #eee;
	padding: 10px 0 10px 20px;
	font-style: italic;
	font-size:13px;
	margin-bottom: 30px;
	p{
		margin-bottom: 0;
	}
	span{
		color: #777;
	}
	a{
		color: #000;
	}
	ul{
		@media #{$r768}{
			margin-bottom: 0;
		}
	}
	@media #{$r768}{
		margin-bottom: 0;
	}
	@media #{$r414}{
		margin-bottom: 10px;
	}
}

.breadcrumbs{
	width: 100%;
	height: auto;
	background: #eee;
	padding: 10px 0 10px 20px;
	font-style: italic;
	font-size:13px;
	margin-bottom: 30px;
	p{
		margin-bottom: 0;
	}
	span{
		color: #777;
	}
	a{
		color: #000;
	}
	ul{
		@media #{$r768}{
			margin-bottom: 0;
		}
	}
	@media #{$r768}{
		margin-bottom: 0;
	}
}

.home{
	&Title{
		padding: 30px 0 60px;
		text-align: center;
		font-size: 28px;
		line-height: 1.2;
		font-weight: 700;
		color: #777777;
		text-transform: uppercase;
		position: relative;
		margin: 0;
		@media #{$r768}{
			font-size: 25px;
		}
		&::before{
			content: '';
			position: absolute;
			background: url(../images/line.png) no-repeat;
			width: 240px;
			height: 22px;
			left: 0;
			right: 0;
			bottom: 25px;
			margin: 0 auto;
		}
	}
}

.home1{
	@media #{$r768}{
		padding-top: 0px;
		padding-bottom: 0;
	}
	&__box{
		.col-md-6{
			@media #{$r768}{
				width: 50%;
				padding: 0 10px;
			}
			@media #{$r414}{
				width: 100%;
				padding: 0 10px;
			}
		}
		.item{
			position: relative;
			text-align: center;
			margin-bottom: 30px;
			height: 279px;
			@media #{$r1024}{
				height: 229px;
			}
			@media #{$r768}{
				height: 178px;
				margin-bottom: 20px;
			}
			@media #{$r414}{
				height: 198px;
			}
			@media #{$r375}{
				height: 179px;
			}
			@media #{$r360}{
				height: 171px;
			}
			@media #{$r320}{
				height: 152px;
			}
			&:hover{
				overflow: hidden;
				.image{
					&::after{
						content: '';
						opacity: 1;
						visibility: visible;
						top: 0;
					}
					img{
						transform: scale(1.1);
						transition: all 0.4s;
					}
				}
				.title{
					bottom: 0px;
					color: #F58220;
					transition: all 0.4s;
					background: rgba(15, 94, 168, 0.68);
				}
				.detail-btn{
					opacity: 1;
					transition: all 0.4s;
				}
			}
			.image{
				&::after{
					content: '';
					display: block;
					position: absolute;
					top: -6px;
					left: 0;
					width: 100%;
					height: 100%;
					z-index: 1;
					background: -webkit-linear-gradient(top, rgba(255, 255, 255, 0.1), rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.7));
					transition: all 0.4s;
					opacity: 0;
					visibility: hidden;
				}
				img{
					width: 100%;
				}
			}
			.title{
				position: absolute;
				bottom: 0px;
				left: 0;
				width: 100%;
				text-transform: uppercase;
				padding: 15px 10px;
				font-size: 16px;
				line-height: 1.2;
				font-weight: 700;
				color: #ffffff;
				background: rgba(0, 186, 255, 0.23);
				z-index: 10;
				transition: all 0.4s;
				@media #{$r768}{
					font-size: 13px;
				}
			}
			.detail-btn{
				position: absolute;
				bottom: calc(50% - 15px);
				left: calc(50% - 55px);
				z-index: 2;
				opacity: 0;
				transition: all 0.4s;
				.btn-1{
					background: #F5811F;
					color: #fff;
					padding: 10px 20px;
					border-radius: 100px;
					transition: all 0.4s;
					&:hover{
						background: #fff;
						color: #000;
						transition: all 0.4s;
					}
				}
			}
		}
	}
}

.home2{
	.homeTitle{
		padding-top: 0;
	}
	&__box{
		.col-md-3{
			@media #{$r768}{
				width: 50%;
				padding: 0 10px;
			}
		}
		.item-news{
			margin-bottom: 30px;
			@media #{$r768}{
				margin-bottom: 20px;
			}
			&:hover{
				.image{
					.news-time{
						opacity: 1;
						visibility: visible;
						transition: all 0.3s;
					}
					img{
						transform: scale(1.1);
						transition: all 0.4s;
					}
				}
				.descrip{
					.title{
						a{
							color: #F58220;
							transition: all 0.3s;
						}
					}
				}
			}
			.image{
				position: relative;
				overflow: hidden;
				transition: all 0.3s;
				img{
					max-width: 100%;
					width: 100%;
					transition:  all 0.4s;
					display: block;
					height: auto;
					transform: scale(1);
				}
				.news-time{
					position: absolute;
					top: 6px;
					left: 6px;
					opacity: 0;
					border-radius: 15px;
					visibility: hidden;
					background: #F58220;
					color: #fff;
					font-size: 12px;
					line-height: 1.2;
					font-weight: 400;
					padding: 5px 15px;
					transition: all 0.3s;
					box-shadow: 0 2px 4px rgba(0,0,0,.26);
				}
			}
			.descrip{
				.title{
					margin: 10px 0;
					height: 40px;
					font-size: 14px;
					line-height: 20px;
					font-weight: 400;
					transition: all 0.3s;
					@media #{$r1024}{
						height: 57px;
					}
					@media #{$r768}{
						height: 37px;
					}
					@media #{$r414}{
						height: 55px;
					}
					@media #{$r360}{
						height: 78px;
					}
					a{
						color: #2586c9;
						font-weight: bold;
						transition: all 0.3s;
					}
				}
				.content{
					text-align: justify;
					p{
						font-size: 13px;
						line-height: 18px;
						font-weight: 400;
						color: #777;
					}
				}
			}
		}
	}
	.detail-view{
		text-align: center;
		margin: 20px 0;
		a{
			background: #f5811f;
			color: #fff;
			padding: 10px 30px;
			border-radius: 100px;
			transition: all 0.4s;
			&:hover{
				background: #2586c9;
				transition: all 0.4s;
			}
		}
	}
}

.gioi-thieu{
	@media #{$r768}{
		padding-top: 80px;
	}
	.main-about{
		.content-about{
			h1{
				font-size: 25px;
				line-height: 34px;
				font-weight: 700;
				color: #0072bc;
				text-transform: uppercase;
				margin: 0px 0 15px;
			}
			i{
				margin-bottom: 30px;
				display: block;
			}
			img{
				width: 100%;
			}
		}
	}
}

.du-an{
	margin: 20px 20px ;
	display: flex;
	flex-wrap: wrap;
	@media #{$r1024}{
		margin-top: 0;
	}
	@media #{$r768}{
		margin-top: 60px;
		padding-bottom: 0;
	}
	@media #{$r414}{
		margin: 0px 5px 0;
	}
	.item{
		display: flex;
		flex-wrap: wrap;
		justify-content: center;
		width: 49%;
		margin: 5px;
		position: relative;
		@media #{$r1024}{
			width: 48%;
		}
		@media #{$r768}{
			width: 100%;
		}
		@media #{$r414}{
			display: block;
		}
		&:hover{
			.img-hover{
				transition: all 0.4s;
				opacity: 1;
			}
		}
		.descrip{
			width: 50%;
			background: #F2F2F2;
			border-bottom-left-radius: 5px;
			@media #{$r414}{
				width: 100%;
			}
			.title{
				background: #3376B5;
				color: #fff;
				text-align: center;
				text-transform: uppercase;
				border-top-left-radius: 5px;
				@media #{$r414}{
					border-top-right-radius: 5px;
				}
				h2{
					margin-top: 0;
					font-size: 20px;
					padding: 10px 0;
					@media #{$r1024}{
						font-size: 15px;
					}
				}
			}
			.content{
				padding: 5px 20px;

				p{
					text-align: justify;
					@media #{$r1024}{
						font-size: 13px;
					}
				}
			}
		}
		.image{
			width: 50%;
			@media #{$r414}{
				width: 100%;
			}
			img{
				width: 100%;
				height: 100%;
				border-top-right-radius: 5px;
				border-bottom-right-radius: 5px;
				@media #{$r414}{
					border-bottom-left-radius: 5px;
				}
			}
		}
		.img-hover{
			position: absolute;
			top: 0;
			left: 0;
			opacity: 0;
			width: 100%;
			height: 100%;
			transition: all 0.4s;
			img{
				width: 100%;
				height: 100%;
				border-radius: 5px;
				transition: all 0.4s;
			}
			.detail-view{
				position: absolute;
				left: calc(50% - 61px);
				bottom: calc(50% - 17px);
				transition: all 0.4s;
				a{
					background: #f5811f;
					color: #fff;
					padding: 10px 30px;
					border-radius: 100px;
					transition: all 0.4s;
					&:hover{
						background: #fff;
						color: #000;
						transition: all 0.4s;
					}
				}
			}
		}
		
		&.change{
			.descrip{
				order: 2;
				border-bottom-right-radius: 5px;
				border-bottom-left-radius: 0px;
				@media #{$r414}{
					order: 1;
				}
				.title{
					border-top-right-radius: 5px;
					border-top-left-radius: 0px;
					@media #{$r414}{
						border-top-left-radius: 5px;
					}
				}
			}
			.image{
				order: 1;
				@media #{$r414}{
					order: 2;
				}
				img{
					border-top-right-radius: 0px;
					border-bottom-right-radius: 0px;
					border-top-left-radius: 5px;
					border-bottom-left-radius: 5px;
					@media #{$r414}{
						border-bottom-right-radius: 5px;
					}
				}
			}
		}
	}
	.pagination{
		text-align: center;
		width: 100%;
		margin-top: 20px;
		ul{
			padding-left: 0 ;
			a{
				position: relative;
				padding: 6px 12px;
				margin-left: -1px;
				line-height: 1.42857143;
				color: #3276b5;
				text-decoration: none;
				background-color: #fff;
				border: 1px solid #ddd;
				display: inline;
				cursor: pointer;
				&:hover{
					z-index: 3;
					color: #fff;
					background-color: #3276b5;
					border-color: #3276b5;
				}
				&.active{
					z-index: 3;
					color: #fff;
					background-color: #3276b5;
					border-color: #3276b5;
				}
			}
		}
	}
}

.cate-thuvien{
	@media #{$r768}{
		margin-top: 50px;
	}
	
	@media #{$r414}{
		margin-top: 0px;
	}
	
	.item{
		position: relative;
		margin-bottom: 30px;
		@media #{$r768}{
			width: 50%;
		}
		@media #{$r414}{
			margin-bottom: 10px;
			padding: 0 10px;
		}
		&:nth-child(2n+1){
			@media #{$r414}{
				padding-right: 5px;
			}
		}
		&:nth-child(2n+2){
			@media #{$r414}{
				padding-left: 5px;
			}
		}

		&:hover{
			.time{
				opacity: 1;
				visibility: visible;
				transition: all 0.4s;
			}
			.image{
				overflow: hidden;
				img{
					transition: all 0.4s;
					transform: scale(1.1);
					overflow: hidden;
				}
			}
		}
		.image{
			overflow: hidden;
			img{
				max-width: 100%;
				width: 100%;
				transition:  all 0.4s;
				display: block;
				height: auto;
				transform: scale(1);
				overflow: hidden;
			}
		}
		.time{
			position: absolute;
			top: 10px;
			left: 25px;
			opacity: 0;
			visibility: hidden;
			border-radius: 15px;
			background: rgba(0, 114, 188, 0.8);
			color: #fff;
			font-size: 14px;
			line-height: 1.2;
			font-weight: 400;
			padding: 5px 15px;
			-webkit-transition: all 0.3s;
			-o-transition: all 0.3s;
			transition: all 0.3s;
		}
		.title{
			position: absolute;
			width: 100%;
			padding: 10px 15px 10px 15px;
			bottom: 0;
			text-transform: uppercase;
			left: 20px;
			line-height: 1.2;
			z-index: 100;
			@media #{$r320}{
				left: 0;
			}
			a{
				font-size: 15px;
				color: #fff;
				@media #{$r414}{
					font-size: 12px;
				}
			}
		}
	}
}

.chi-tiet-du-an{
	@media #{$r768}{
		padding: 90px 0 0;
	}
	@media #{$r414}{
		padding: 20px 0 0;
	}
	.breadcrumbs{
		@media #{$r1024}{
			margin-bottom: 0;
		}
	}
	.sapo{
		background: #e6eef1;
		padding: 10px 20px;
		margin: 0 0 20px;
		line-height: 30px;
		font-size: 16px;
		text-align: justify;
		margin-top: 30px;
	}
	.main{
		@media #{$r1024}{
			padding: 0;
		}
		.sidebar-duan{
			padding-left: 0;
			@media #{$r1024}{
				display: none;
			}
			ul{
				list-style: none;
				padding-left: 0;
				li{
					border-bottom: 1px solid #f1f1f1;
					a{
						padding: 17px 15px 17px 10px;
						display: block;
						position: relative;
						text-transform: uppercase;
						transition: all 0.4s;
						&:hover{
							background: #0072bc;
							color: #fff;
							transition: all 0.4s;
							padding: 17px 20px;
						}
						&::after{
							@extend %icon;
							content: '\e88d';
							display: block;
							position: absolute;
							font-size: 18px;
							right: 10px;
							top: calc(50% - 11px);
						}
						&.active{
							background: #0072bc;
							color: #fff;
							transition: all 0.4s;
							padding: 17px 20px;
						}
					}
				}
				
			}
			.fixed-header{
				position: fixed;
				top: 80px;
				width: 19%; 
				background: #fff;
				z-index: 20;
				@media #{$r1440}{
					width: 25%;
				}
				@media #{$r1366}{
					width: 27%;
				}
				@media #{$r1280}{
					width: 28%;
				}
			}
			.hotline{
				text-align: center;
				background: #f47922;
				color: #fff;
				padding: 10px;
				a{
					display: block;
					color: #fff;
				}
			}
		}
		.sidebar-post{
			@media #{$r1024}{
				display: none;
			}
		}
		.content{
			@media #{$r1024}{
				max-width: 100%;
				flex: 0 0 100%;
			}
			.title{
				font-size: 25px;
				line-height: 34px;
				font-weight: 600;
				color: #0072bc;
				text-transform: uppercase;
				margin: 15px 0;
				@media #{$r320}{
					font-size: 22px;
				}
			}
			.share{
				.facebook{
					vertical-align: bottom;
					display: inline-block;
					background-color: #2f5597;
					border-radius: 4px;
					color: #fff;
					padding: 0 5px;
					@media #{$r320}{
						padding: 0;
					}
					img{
						display: inline-block;
						margin-right: 5px;
						max-width: 100%;
						height: auto;
						margin-bottom: -5px;
					}
				}
				.google{
					vertical-align: bottom;
					display: inline-block;
					background-color: #dd5044;
					border-radius: 4px;
					color: #fff;
					padding: 0 5px;
					img{
						display: inline-block;
						margin-right: 5px;
						max-width: 100%;
						height: auto;
						margin-bottom: -5px;
					}
				}
				.tweet{
					img{
						vertical-align: bottom;
						display: inline-block;
						background-color: #1b95e0;
						border-radius: 4px;
					}
				}
			}
			.text{
				.text-child{
					.tit-text{
						font-size: 15px;
						line-height: 1.4;
						font-weight: 700;
						color: #fff;
						text-transform: uppercase;
						margin-bottom: 20px;
						margin-top: 20px;
						background: #f47922;
						display: inline-block;
						padding: 6px 15px;
					}
				}
				img{
					width: 100%;
				}
			}
			.contacts-form{
				.form-group {
					margin-bottom: 15px;
					&:last-child{
						button{
							background: #0072bc;
							color: #fff;
							float: right;
						}
					}
				}
				label{
					display: inline-block;
					max-width: 100%;
					margin-bottom: 5px;
					font-weight: 700;
				}
				.form-control {
					display: block;
					width: 100%;
					height: 34px;
					padding: 6px 12px;
					font-size: 14px;
					line-height: 1.42857143;
					color: #555;
					background-color: #fff;
					background-image: none;
					border: 1px solid #ccc;
					border-radius: 4px;
				}
			}
		}
	}
}

.cate-tin-tuc{
	padding-top: 40px;
	@media #{$r768}{
		padding-top: 100px;
	}
	@media #{$r414}{
		padding-top: 20px;
	}
	.main{
		@media #{$r1024}{
			padding-bottom: 30px;
		}
		@media #{$r768}{
			padding-left: 0;
			padding-right: 0;
		}
		.col-md-6{
			@media #{$r768}{
				width: 100%;
			}
		}
		.row-right{
			.col-md-6{
				@media #{$r768}{
					width: 50%;
				}
				@media #{$r414}{
					width: 100%;
				}
			}
			.item{
				.title{
					a{
						font-size: 11px;
					}
				}
			}
		}
		.item{
			position: relative;
			margin-bottom: 17px;
			overflow: hidden;
			@media #{$r768}{
				width: 100%;
			}
			@media #{$r414}{
				margin-bottom: 10px;
				padding: 0 10px;
			}
			&:nth-child(2n+1){
				@media #{$r414}{
					padding-right: 0px;
					padding-left: 0px;
				}
			}
			&:nth-child(2n+2){
				@media #{$r414}{
					padding-left: 5px;
				}
			}

			&:hover{
				overflow: hidden;
				.time{
					opacity: 1;
					visibility: visible;
					transition: all 0.4s;
				}
				.image{
					overflow: hidden;
				}
				img{
					transition: all 0.4s;
					transform: scale(1.1);
					overflow: hidden;
				}
				.title{
					a{
						color: #5adae8;
					}
				}
			}
			.image{
				height: 100%;
				width: 100%;
				position: relative;
				&::after{
					content: '';
					display: block;
					position: absolute;
					top: 0px;
					left: 0;
					width: 100%;
					height: 100%;
					z-index: 1;
					background: -webkit-gradient(linear, left top, left bottom, from(top), color-stop(rgba(255, 255, 255, 0.1)), color-stop(rgba(0, 0, 0, 0.3)), to(rgba(0, 0, 0, 0.7)));
					background: -webkit-linear-gradient(top, rgba(255, 255, 255, 0.1), rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.7));
					background: -o-linear-gradient(top, rgba(255, 255, 255, 0.1), rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.7));
					background: linear-gradient(top, rgba(255, 255, 255, 0.1), rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.7));
					transition: all 0.4s;
				}
				img{
					max-width: 100%;
					width: 100%;
					transition:  all 0.4s;
					display: block;
					height: auto;
				}
			}
			.time{
				position: absolute;
				top: 10px;
				left: 25px;
				opacity: 0;
				visibility: hidden;
				border-radius: 15px;
				background: rgba(0, 114, 188, 0.8);
				color: #fff;
				font-size: 14px;
				line-height: 1.2;
				font-weight: 400;
				padding: 5px 15px;
				-webkit-transition: all 0.3s;
				-o-transition: all 0.3s;
				transition: all 0.3s;
			}
			.title{
				position: absolute;
				width: 100%;
				padding: 10px 15px 10px 15px;
				bottom: 0;
				text-transform: uppercase;
				left: 0px;
				line-height: 1.2;
				z-index: 100;
				@media #{$r320}{
					left: 0;
				}
				a{
					font-size: 15px;
					color: #fff;
					@media #{$r414}{
						font-size: 12px;
					}
				}
			}
		}
	}
	.tab-cate{
		.tabs{
			.tab-link{
				position: relative;
				font-size: 14px;
				line-height: 1.4;
				padding-right: 2px;
				font-weight: 700;
				color: #333;
				display: inline-block;
				text-transform: uppercase;
				background: #ccc;
				padding: 10px 15px;
				margin: 0 3px;
				@media #{$r414}{
					font-size: 9px;
					padding: 10px 5px;
				}
				&.current{
					background: #2586C9;
					a{
						color: #fff;
					}
					&::after{
						content: '';
						position: absolute;
						width: 0;
						height: 0;
						left: 0;
						right: 0;
						margin: 0 auto;
						bottom: -15px;
						border-style: solid;
						border-width: 15px 15px 0 15px;
						border-color: #2586c9 transparent transparent transparent;
					}
				}
			}
		}
		.tab-content{
			padding-top: 30px;
			margin-top: 40px;
			border-top: 2px dashed #2586c9;
			@media #{$r768}{
				padding-left: 0;
				padding-right: 0;
			}
			h1{
				font-size: 25px;
				line-height: 34px;
				font-weight: 600;
				color: #0072bc;
				text-transform: uppercase;
				margin: 15px 0;
			}
			img{
				width: 100%;
			}
			.image{
				overflow: hidden;
				img{
					max-width: 100%;
					width: 100%;
					transition:  all 0.4s;
					display: block;
					height: auto;
					transform: scale(1);
				}
			}
			#customers {
				font-family: "Trebuchet MS", Arial, Helvetica, sans-serif;
				border-collapse: collapse;
				width: 100%;
			}
			
			#customers td, #customers th {
				border: 1px solid #ddd;
				padding: 8px;
				text-align: center;
				a{
					color: #249cea;
					font-weight: 500;
				}
			}
			
			#customers tr:nth-child(even){background-color: #f2f2f2;}
			
			#customers tr:hover {background-color: #ddd;}
			
			#customers th {
				padding-top: 12px;
				padding-bottom: 12px;
				text-align: center;
				background-color: #0F5EA8;
				color: white;
			}
			.item{
				overflow: hidden;
				&:hover{
					img{
						transform: scale(1.1);
						transition:  all 0.4s;
					}
					.title{
						a{
							color: #F58220;
							transition:  all 0.4s;
						}
					}
				}
			}
			.col-left{
				.item{
					.title{
						margin-top: 10px;
						a{
							font-size: 16px;
							font-weight: 600;
							transition:  all 0.4s;
						}
						.time{
							margin: 5px 0;
						}
						.des{
							margin: 0;
						}
					}
				}
			}
			.col-center{
				@media #{$r768}{
					margin-top: 30px;
				}
				.item{
					display: flex;
					margin-bottom: 20px;
					.image{
						width: 40%;
						@media #{$r768}{
							width: 20%;
						}
					}
					.title{
						width: 60%;
						padding-left: 10px;
						@media #{$r768}{
							width: 80%;
						}
						a{
							font-size: 14px;
							font-weight: 600;
							transition:  all 0.4s;
						}
						.time{
							margin: 5px 0;
						}
					}
				}
			}
			.col-right{
				ul{
					margin: 0;
					list-style: none;
					@media #{$r768}{
						padding-left: 10px;
					}
					li{
						position: relative;
						margin-bottom: 10px;
						&::before{
							@extend %icon;
							content : '\e887';
							display: block;
							position: absolute;
							left: -20px;
							top: 2px;
						}
						&:hover{
							a{
								color: #F58220;
								transition: all 0.4s;
							}
						}
					}
				}
			}
		}
	}
}

.site{
	&Main {
		padding-top: 30px;
		padding-bottom:40px;
		position: relative;
		@media #{$r1024}{
			
			padding-top:10px;
			margin:0;
			width:100%;
		}
		@media #{$r768}{
			padding-bottom:0;
			padding-top: 0px;
		}
		@media #{$r414}{
			padding-bottom:0;
			padding-top: 20px;
		}
		&:before{
			content:'';
			display: block;
			width:98%;
			margin:0 auto 20px;
			@media #{$r1024} {
				width:100%;
			}
			@media #{$r414} {
				margin:0 auto 5px;
			}
		}
		.breadcrumb{
			width: 100%;
			height: auto;
			background: #eee;
			padding: 10px 0 10px 20px;
			font-style: italic;
			font-size:13px;
			p{
				margin-bottom: 0;
			}
			span{
				color: #777;
			}
			a{
				color: #000;
			}
			ul{
				@media #{$r768}{
					margin-bottom: 0;
				}
			}
			@media #{$r768}{
				margin-bottom: 0;
			}
		}
		.postDetail{
			.sapo{
				background: #e6eef1;
				padding: 10px 20px;
				margin: 0 0 20px;
				text-align: justify;
				margin-top: 30px;
			}
			.panel{
				padding-left: 0;
				ul{
					padding-left: 40px;
					li{
						padding:0 0 5px;
					}
				}
			}
			h3{
				font-size: 18px;
			}
		}
	}
	&Title{
		margin:20px 0 10px;
		font-size:18px;
		font-weight:bold;
		color:#0072bc;
		text-transform: uppercase;
	}
}

.cate{
	&Info{
		padding:10px 0 20px;
		margin-bottom:25px;
		border-bottom: 1px dotted #ddd;
		display: table;
		width:1005;
		&-desc{
			font-size:14px;
			font-style:italic;
			text-align: justify;
			b{
				color: #F26649;
			}
		}
	}
	&Main{
		.media{
			margin-bottom: 20px;
			padding-bottom:20px;
			border-bottom:1px dotted #d8d8d8;
			@media #{$r414}{
				flex-wrap: wrap;
			}
			&-pic{
				width:150px;
				@media #{$r375}{
					width:120px;
				}
				@media #{$r320}{
					width:100px;
				}
				img{
					width:100%;
					height:150px;
					@media #{$r375}{
						height:120px;
					}
					@media #{$r320}{
						height:100px;
					}
				}
			}
			&-title{
				font-weight: 600;
				@extend %font1;
				color:$color1;
				font-size:17px;
				@media #{$r375}{
					font-size:15px;
				}
			}
			&-desc{
				font-size:13px;
				margin:10px 0 5px;
				text-align: justify;
				@media #{$r414}{
					height:55px;
					overflow: hidden;
					text-align: left;
				}
			}
			&-more{
				padding-top:10px;
				a{
					color:#e3a261;
					font-size:11px;
					background:#0061c3;
					color:#fff;
					border-radius: 30px;
					padding-right:20px;
					padding-left:10px;
				}
			}
		}
	}
	&Main-1{
		padding-top:15px;
		@media #{$r414} {
			padding-top:10px;
		}
		
		.media{
			padding-bottom:20px;
			@media #{$r414}{
				flex-wrap: wrap;
			}
			&-pic{
				width:240px;
				height:150px;
				overflow: hidden;
				@media #{$r414}{
					width:130px;
					height:90px;
				}
				@media #{$r320}{
					width:110px;
					height:80px;
				}
				
				img{
					width:100%;
				}
			}
			&-title{
				font-weight: 600;
				@extend %font1;
				color:$color1;
				font-size:17px;
				@media #{$r414}{
					font-size:13px;
					font-weight: normal;
				}
			}
			&-desc{
				font-size:13px;
				margin:10px 0 5px;
				text-align: justify;
				@media #{$r414}{
					height:55px;
					overflow: hidden;
					display: none;
				}
			}
			&-more{
				padding-top:10px;
				@media #{$r320}{
					padding-top:0;
				}
				a{
					color:#f26649;
					text-transform: uppercase;
					font-size:13px;
					font-weight: 600;
					text-decoration: underline;
					@media #{$r320}{
						font-size:11px;
					}
				}
				i{
					vertical-align: middle;
					color:#f26649;
					font-size:18px;
				}
			}
		}
	}
}

.lien-he{
	padding-top: 40px;
	@media #{$r768}{
		padding-top: 100px;
	}
	@media #{$r414}{
		padding-top: 20px;
	}
	.map{
		img{
			width: 100%;
		}
	}
	.description{
		.title-h3 {
			font-size: 20px;
			line-height: 1;
			font-weight: 600;
			color: #333;
			text-transform: uppercase;
			margin: 30px 0 25px 0;
		}
		.title-contact {
			font-size: 18px;
			line-height: 1;
			font-weight: 600;
			color: #0072bc;
			text-transform: uppercase;
			margin: 10px 0 15px 0;
			@media #{$r360}{
				font-size: 16px;
			}
			@media #{$r320}{
				font-size: 15px;
			}
		}
		.contact-right{
			input{
				border-radius: 5px;
				border: 0;
				background: #f1f1f1;
				margin-bottom: 15px;
				resize: none;
				padding: 6px 10px;
				width: 100%;
			}
			textarea{
				border-radius: 5px;
				border: 0;
				background: #f1f1f1;
				margin-bottom: 15px;
				resize: none;
				padding: 6px 10px;
				width: 100%;
			}
			.th-btn{
				display: inline-block;
				text-transform: uppercase;
				border-radius: 5px;
				font-size: 16px;
				line-height: 36px;
				font-weight: 400;
				padding: 0 45px;
				background: #2586c9;
				color: #fff;
				border: none;
			}
		}
	}
}
.post{
	&Other{
        margin: 30px 0 0;
        border-top:1px dotted #ccc;
        ul{
            margin:0;
            li{
                a{
                    color:#0061c3;
                    text-decoration: underline;
                }
            }

        }
        h3{
            font-size:18px;
            font-weight: 600;
            i{
                margin-right:5px;
            }
        }
        .media{
            padding-bottom:10px;
            @media #{$r414}{
                flex-wrap: wrap;
            }
            &-pic{
                width:70px;
                img{
                    width:100%;
                    height:70px;
                    border-radius: 4px;
                }
            }
            &-body{
                padding-left:15px;
                align-self: center;
            }
            &-title{
                color:#333;
                font-size:13px;
                line-height: 20px;
                a{
                    color:#0061c3;
                    text-decoration: none;
                }
            }
            
            
		}
		.panel{
			padding:5px 15px;
            ul{
                list-style: none;
                padding-left: 0;
                @media #{$r414} {
                    padding-left:0;
                }
                li{
                    padding-left: 22px;
					position: relative;
					margin:5px 0;
                    &::before{
                        @extend %icon;
                        content: '\e90d';
                        display: flex;
                        width: 20px;
                        height: 16px;
                        position: absolute;
                        left: -2px;
                        top: 3px;
                        color: #0061c3;
                    }
                    a{
                        color:#0061c3;
                        text-decoration: none;
                        font-weight: normal;
                    }
                }
            }
            
        }
    }
}	

$r1440: "(max-width:1440px)";
$r1366: "(max-width:1366px)";
$r1280: "(max-width:1280px)";
$r1024: "(max-width:1024px)";
$r768: "(max-width:812px)";
$r414: "(max-width:414px)";
$r375: "(max-width:375px)";
$r360: "(max-width:360px)";
$r320: "(max-width:320px)";
$main:#00619b;
$color1:#004689;

body {
	margin: 0;
	
}
%font1{
	font-family:-apple-system,system-ui,BlinkMacSystemFont,"Segoe UI",Roboto,"Helvetica Neue",Arial,sans-serif;
	@media #{$r414} {
		font-family: arial;
	}
}
.regft {
    display: none;
    @extend %font1;

    @media #{$r768} {
        display: flex;
        justify-content: center;
        align-content: center;
        width: 100%;
        height: 60px;
        position: fixed;
        background: rgba(0,0,0,0.5);
        left: 0;
        bottom: 0;
        z-index: 111;

        a {
            &:hover,
            &:focus {
                text-decoration: none;
            }

            &:nth-child(1) {
                background: #E43C3B;
                color: #fff;
                display: block;
                margin: 10px 70px;
                text-align: center;
                width: 70%;
                height: 40px;
                line-height: 40px;
                font-weight: bold;
                border-radius: 20px;
                text-transform: uppercase;
            }

            &:nth-child(2) {
                background: #e23d3a;
                margin-right: 10px;
                box-shadow: 0px 2px 0px 0px #8f2725;

                @media #{$r375} {
                    font-size: 13px;
                    text-align: center;
                    padding-left: 0;

                    i {
                        display: none;
                    }
                }
            }
        }
    }
}

.refuse {
    position: fixed;
    bottom: 0;
    background: #00183A;
    color: #fff;
    font-style: italic;
    width: 100%;
    padding: 5px 0;
    z-index: 5;
    @media #{$r414}{
        position: inherit;
        text-align: center;
        padding: 10px 0;
		z-index: 0;
		margin-bottom:25px;
    }
}
footer{
	padding:45px 0;
	width:100%;
	font-family: arial;
	background: url(../images/footer.jpg) bottom no-repeat;
	border-top: 1px solid #ccc;
	@media #{$r1024} {
		padding:0px;
	}
	@media #{$r768} {
		padding:0px 0 60px;
	}
	@media #{$r414} {
		padding:20px 0 60px;
		background-color: #D6D6D6;
	}
	.col-md-4, .col-md-5{
		@media #{$r768}{
			width: 50%;
		}
		@media #{$r414}{
			width: 100%;
		}
	}
	.title-footer{
		font-size: 18px;
		line-height: 1.2;
		font-weight: 700;
		color: #333;
		margin-bottom: 10px;
		text-transform: uppercase;
		@media #{$r768}{
			font-size: 17px;
		}
		span{
			display: block;
			color: #2586c9;
			margin-top: 10px;
		}
	}
	.col-md-offset-3{
		margin-left: 25%;
		@media #{$r768}{
			margin-left: 0;
		}
		.title-footer {
			font-size: 18px;
			line-height: 1.2;
			font-weight: 700;
			color: #333;
			margin-bottom: 10px;
			text-transform: uppercase;
		}
		.wpcf7-submit{
			@media #{$r414}{
				margin-left: 0;
			}
		}
		.form-subscriber{
			label{
				font-size: 14px;
				line-height: 1.2;
				font-weight: 400;
				color: #999999;
				margin-bottom: 5px;
			}
			#form_register{
				margin-top: 10px;
				margin-bottom: 20px;
				.col-sm-6{
					width: 50%;
					float: left;
					padding-right: 15px;
					.form-control{
						display: block;
						width: 100%;
						height: 34px;
						padding: 6px 12px;
						font-size: 14px;
						color: #555;
						background-color: #fff;
						border-radius: 5px;
						border: none;
					}
				}
				input{
					width: calc(100% - 80px);
					height: 34px;
					padding: 6px 12px;
					font-size: 14px;
					color: #555;
					background-color: #fff;
					border-radius: 5px;
					border: none;
				}
				.th-btn{
					display: inline-block;
					text-transform: uppercase;
					margin-left: 10px;
					border-radius: 5px;
					font-size: 15px;
					line-height: 36px;
					font-weight: 400;
					padding: 0 10px;
					background: #2586c9;
					border: none;
					color: #fff;
				}
			}
		}
		.btn-follow{
			a{
				color: #246c99;
				font-weight: bold;
				font-size: 18px;
				margin-right: 20px;
				i{
					border: 1px solid #246c99;
					background: #246c99;
					font-size: 16px;
					color: #ccc;
					padding: 3px 1px 0 1px;
				}
			}
		}
	}
	.line{
		width:40%;
		display: block;
		margin: 10px auto;
		border-bottom: 1px solid #fff;
	}
	.name{
		text-transform: uppercase;
		color:#50ADE0;
		font-size:22px;
		font-weight: 600;
		margin: 10px 0 5px;
		@media #{$r414}{
			font-size: 20px;
		}
		@media #{$r375}{
			font-size: 18px;
		}
		@media #{$r360}{
			font-size: 17px;
		}
		@media #{$r320}{
			font-size: 15px;
		}
	}
	.phanphoi{
		color: #000;
		font-weight: bold;
		margin-top: 10px;
		font-size: 16px;
		@media #{$r414}{
			font-size: 15px;
		}
		@media #{$r375}{
			font-size: 13px;
		}
		@media #{$r360}{
			font-size: 12px;
		}
		@media #{$r320}{
			font-size: 11px;
		}
	}
	.phone{
		text-transform: uppercase;
		color:#f6dd79;
		font-weight: bold;
		font-size:13px;
		background: #8BC24B;
		color:#222;
		display: table;
		padding: 10px 20px;
		border-radius: 24px;
		margin: 0 auto 10px;
		a{
			color:#fff;
		}
	}
	.add{
		font-size:12px;
	}
	.copy{
		border-top:1px solid #ccc;
		padding-top: 5px;
		color:#6a4e4c;
		margin: 10px auto 0;
		font-weight: 600;
		display: table;
	}
	
}
.footDoiTac{
	ul{
		padding-bottom: 20px;
		list-style: none;
		border-bottom: 1px solid #4F6C90;
		width: 100%;
		@media #{$r768}{
			padding-left: 50px;
			padding-top: 10px;
		}
		@media #{$r414}{
			width: 90%;
			line-height: 20px;
		}
		.fra{
			position: relative;
			margin-bottom: 8px;
			&::before{
				content: "";
				display: block;
				background-image: url(../images/flag-france.png);
				background-repeat: no-repeat;
				width: 47px;
				height: 17px;
				position: absolute;
				left: -50px;
				top: 3px;
			}
		}
		.vn{
			position: relative;
			&::before{
				content: "";
				display: block;
				background-image: url(../images/flag-vn.png);
				background-repeat: no-repeat;
				width: 47px;
				height: 17px;
				position: absolute;
				left: -50px;
				top: 3px;
			}
		}
	}
}
.dt1{
	display: none;
	@media #{$r414}{
		display: block;
	}
}
.dt2{
	@media #{$r414}{
		display: none;
	}
}
.scrolltop {
    position: fixed;
    right: 20px;
    bottom: -4px;
    width: 305px;
    transition: ease-in-out 0.1s;
    color: #fff;
    display: flex;
    line-height: 35px;
	text-transform: uppercase;
	font-style: normal;
	opacity: 0.7;
	z-index: 15;
	@media #{$r414} {
		bottom:140px;
		right:5px;
		border-radius: 4px;;
		width:25px;
		height:25px;
		display: flex;
		align-items: center;
		background:rgba(0,0,0,.2);
	}
	
	img {
		width: 25px;
		height: 25px;
		margin: 5px auto;
		margin-right: 10px;
		@media #{$r414} {
			margin-right:0;
		}
		
	}
	&:hover {
		opacity: 1
	}
	span{
		@media #{$r414} {
			display:none;
		}
		
	}
}


#toc_container{
    background:#f5f5f5!important;
    border:1px solid #dedede!important;
    margin:25px auto!important;
    padding:10px 20px!important;
    @media #{$r414} {
		margin:15px auto!important;
		width:100%!important;
    }
    a{
        color:#955b03;
    }
    
}
.Paris_sv .main .content.scroll_check{
	padding-top:0;
	padding-bottom: 0;
}
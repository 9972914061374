@import "setup";
@import "main";
@import "layout";
@import "footer";
@import "header";


[class^="icon-"]:before, [class*=" icon-"]:before,[class^="flex-"]:before, [class*=" flex-"]:before,%icon {
	font-family: "fontello";
	font-style: normal;
	font-weight: 400;
	speak: none;
	display: inline-block;
	text-decoration: inherit;
	width: 1em;
	margin-right: .2em;
	text-align: center;
	font-variant: normal;
	text-transform: none;
	line-height: 1em;
	margin-left: .2em;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale
}
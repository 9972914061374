body{
    padding-top:90px;
    @media #{$r1024}{
        padding-top:80px;
    }
}
header{
    position: fixed;
    width:100%;
    top:0;
    z-index: 1000;
    background: rgba(15, 94, 168, 0.85);
    opacity: 1;
    @extend %font1;
    @media #{$r1024}{
        height:80px;
    }
    
    .siteHeader {
        
        &-bg{
            background:rgba(0,0,0,.8);
			width:100%;
			height:100%;
			content:'';
			display: none;
			position: fixed;
            top:0;
            left:0;
            z-index: 2;
            animation:opac 0.4s;
			&.active{
                display: block;
			}
        }
        &__section {
            display: flex;
            align-items: center;
            @media #{$r1024}{
                display: block;
                width:100%;
            }
        }
        &__item {
            padding: 25px 20px;
            font-size: 14px;
            text-transform: uppercase;
            cursor: pointer;
            color: #fff;
            font-weight: 600;
            @media #{$r1024}{
                padding: 11px 12px 11px 37px;
                display: block;
                font-weight: 600;
                border-top:1px dotted rgba(255,255,255,.2);
            }
            &-menuHome{
                @media #{$r1024}{
                    display: none;
                }
            }
            &-logosb{
                display: none;
                @media #{$r1024}{
                    display: block;
                    text-align: center;
                }
                img{
                    max-width:90%;
                    margin:20px 0 0;
                }
            }
        }
        
        &__item+&__item {
            margin-left: 5px;
        }
        &Main{
            @media #{$r1024}{
                display: none;
                width:270px;
                background:rgba(15,94,168,0.85);
                position: fixed;
                height:100%;
                left:0;
                top:0;
                z-index: 3;
                overflow: auto;
                padding-bottom:20px;
            }
            &.active{
                display: block;
                animation:animateleft 0.4s;
            }
            .menu{
                display: block;
                list-style: none;
                padding: 0;
                width: 100%;
                @media #{$r1024}{
                    display: block;
                }
                li{
                    position: relative;
                    float: left;
                    color: #ffffff;
                    line-height: 19px;
                    padding: 15px 0px;
                    transition: 0.8s;
                    @media #{$r1024}{
                        float: none;
                        padding: 0px;
                    }
                    &:nth-child(n+5){
                        float: right;
                        @media #{$r1024}{
                            float: none;
                        }
                    }
                    &::before{
                        content: '';
                        display: block;
                        width: 1px;
                        height: 20px;
                        position: absolute;
                        top: 15px;
                        right: 0;
                        background: -webkit-linear-gradient(top, transparent, #fff, transparent);
                        @media #{$r1024}{
                            display: none;
                        }
                    }
                    &:first-child()::after{
                        content: '';
                        display: block;
                        width: 1px;
                        height: 20px;
                        position: absolute;
                        top: 15px;
                        left: 0;
                        background: -webkit-linear-gradient(top, transparent, #fff, transparent);
                        @media #{$r1024}{
                            display: none;
                        }
                    }
                    &:nth-child(4)::before{
                        display: none;
                    }
                    &:hover{
                        a{
                            &::after{
                                content: '';
                                display: block;
                                width: 35px;
                                height: 1px;
                                position: absolute;
                                bottom: 8px;
                                left: calc(50% - 18px);
                                background: -webkit-linear-gradient(left, transparent, #fff, transparent);
                                transition: 0.8s;
                            }
                        }
                    }
                    &.active a{
                        text-shadow: 0 2px 4px rgba(0,0,0,.26);
                        &::after{
                            content: '';
                            display: block;
                            width: 35px;
                            height: 1px;
                            position: absolute;
                            bottom: 8px;
                            left: calc(50% - 18px);
                            background: -webkit-linear-gradient(left, transparent, #fff, transparent);
                            @media #{$r1024}{
                                display: none;
                            }
                        }
                    }
                }
                &Sub{
                    &:hover{
                        .menuSubBox{
                            display: block;
                            animation:opac 0.4s;
                            @media #{$r1024}{
                                display: none;
                            }
                        }
                        &>a{
                            color:#e3a261;
                        }
                    }
                    &Box{
                        display: none;
                        position: absolute;
                        width:80%;
                        right:16px;
                        top:95px;
                        background:#2f3d6b;
                        padding:30px 40px;
                        color:#fff;
                        @media #{$r1024}{
                            position: inherit;
                            width:100%;
                            padding:10px 15px;
                        }
                        ul{
                            list-style: none;
                            padding:0;
                            li{
                                border-bottom:1px dotted rgba(255,255,255,.2);
                                padding:10px 0;
                                font-size:13px;
                                &:last-child{
                                    border-bottom:none;
                                }
                            }
                        }
                        a{
                            color:#fff;
                            transition: 0.1s;
                            &:hover{
                                color:rgba(255,255,255,.7);
                            }
                        }
                    }
                    &Title{
                        text-transform: uppercase;
                        font-size:16px;
                        font-weight: 600;
                        position: relative;
                        padding-bottom:15px;
                        margin-bottom: 10px;
                        @media #{$r1024}{
                            margin-top:10px;
                        }
                        &:after,
                        &::before{
                            content:'';
                            display: block;
                            position: absolute;
                        }
                        &:before{
                            width:80px;
                            height:3px;
                            background:#fff;
                            left:0;
                            bottom:-1px;
                        }
                        &:after{
                            width:100px;
                            width:100%;
                            height:1px;
                            background:rgba(255,255,255,.2);
                            left:0;
                            bottom:0;
                        }
                    }
                }
            }
        }
        &Logo {
            background: rgba(15,94,168,0.85);
            padding: 27px 15px 15px 15px;
            position: absolute;
            bottom: -10px;
            left: calc(50% - 110px);
            width: 200px;
            height: calc(100% - -20px);
            border-radius: 0px 0 5px 5px;
            @media #{$r1024}{
                text-align: center;
                width:180px;
                margin:0 auto;
                position: relative;
                display: block;
                height: auto;
                left: auto;
                bottom: 0;
                background: none;
            }
            @media #{$r414}{
                width:150px;
                height:80px;
            }
            img{
                height: auto;
                width: 100%;
                @media #{$r1024}{
                    position: absolute;
                    top:5px;
                    left:0;
                    height:70px;
                    max-width: 100%;
                    width: 100%;
                }
                @media #{$r414}{
                    top:6px;
                    height: 65px;
                }
            }
        }
        &Nav,
        &Call{
            position: absolute;
            top:20px;
            display: none;
            @media #{$r1024}{
                display: flex;
            }
        }
        &Nav{
            font-size:30px;
            width:40px;
            height:40px;
            left:5px;
            color:#fff;
            padding-right:35px;
            border-right:1px solid #fff;
            @media #{$r768}{
                left:20px;
            }
            @media #{$r414}{
                left:30px;
            }
        }
        &Call{
            background: url(../images/call.png) no-repeat center;
            width: 40px;
            height: 40px;
            border-radius: 4px;
            margin: 9px 10px;
            position: absolute;
            right: 5px;
            top: 0;
            z-index: 3;
            display: none;
            @media #{$r1024}{
                display: block;
                right: -20px;
                top: 10px;
            }
            @media #{$r768}{
                right: -5px;
            }
            @media #{$r414}{
                right: 0px;
            }
        }
        &__item.active {
            background-color: #fff;
        }
        &Regist,&Phone{
            border-radius: 4px;
            color:#fff;
            padding: 9px 15px;
            font-size: 14px;
            font-weight: 600;
            text-transform: uppercase;
            
            @media #{$r1024}{
                margin:0 5px;
                display: block;
                text-align: center;
                border-radius: 6px;
            }
        }
        &Regist{
            background:#e02646;
            margin-left:10px;
            border-radius: 20px;
            padding: 10px 20px;
            @media #{$r1024}{
                margin-left:5px;
                margin-top:6px;
            }
        }
        &Phone{
            background:#8bc34a ;
            margin-left:20px;
            border-radius: 20px;
            padding: 7px 10px;
            @media #{$r1024}{
                margin-left:5px;
                margin-top: 10px;
            }

        }
    }
    
    &.menufix{
        box-shadow: 0 0 4px rgba(0,0,0,.3);
        animation:animatetop 0.4s;
        z-index: 999;
        @media #{$r1024}{
            animation:none;
        }
        .siteHeader{
            &__item{
                padding:30px 20px;
                @media #{$r1024}{
                    padding:9px 12px;
                }
                @media #{$r768}{
                    padding:9px 12px;
                }
            }
            &Logo{
                background: none;
                bottom: -20px;
                left: calc(50% - 90px);
                padding-top: 10px;
                @media #{$r1024}{
                    bottom: auto;
                    left: auto;
                }
                img{
                    transition: 0.3s;
                    height: auto;
                    @media #{$r1024}{
                        height: auto;
                    }
                    @media #{$r414}{
                        height: 65px;
                    }
                }
            }
            &Main{
                .menu{
                    &Sub{
                        &Box{
                            top:65px;
                        }
                    }
                }
            }
        }
    }
}







